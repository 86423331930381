
<template>
    <div class="flex items-center quick__insights p-2">
        <feather-icon
          style="cursor: pointer"
          icon="InfoIcon"
          svgClasses="h-4 w-4"
          class="ml-1"
        />
        <span class="ml-3 text-sm">
            QUICK INSIGHT: <b>9.8 months</b> your average time for returning customers (<b>+2.7 months</b> compared to other Fresh Clinics)
        </span>
    </div>
</template>

<script>

export default {
  props: {},
  data() {
    return {};
  },
  
};
</script>

<style scoped>
  .customcard{
    min-height: 70vh;
  }
  .quick__insights{
    background: #D8EFFE;
    border: 1.07993px solid #C3E2F7;
    border-radius: 7px;
    color: #0098F8; 
  }
</style>
