<template>
  <div>
    <vs-row vs-w="12">
      <vs-col vs-lg="4" class="filter-menu-container">
        <vs-collapse :accordion="true">
          <vs-collapse-item
            icon-arrow="arrow_drop_down"
            v-for="category in filteredCategories"
            :key="category.id"
            :class="{ active: selectedCategory.id === category.id }"
            @click="changeCategoryFunc(category)"
          >
            <div slot="header">
              {{ category.label }}
              <span v-if="hasSelectedFilter(category)" class="filter-icon">
                <vs-icon icon="check_circle" color="rgb(61,201,179)"></vs-icon>
              </span>
            </div>

            <div
              class="filter-menu"
              v-for="subCategory in category.subCategory"
              :key="subCategory.uid"
              :class="{ active: selectedSubCategory.uid === subCategory.uid }"
              @click="applyChangesConfirmation(subCategory)"
            >
              {{ subCategory.label }}
              <span v-if="(selectedData[subCategory.filterKey]) ? selectedData[subCategory.filterKey].length : false" class="filter-icon">
                <vs-icon icon="check_circle" color="rgb(61,201,179)"></vs-icon>
              </span>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vs-col>
      <vs-col vs-lg="8" class="filter-table-container" ref="filter-table">
        <!-- Multiple Type selection -->
        <vs-table
          v-show="selectedSubCategory"
          :class="{ active: subCategory.uid === selectedSubCategory.uid }"
          v-for="subCategory in multipleTypeCategories"
          :key="subCategory.uid"
          multiple
          :value="selectedFilters[selectedSubCategory.filterKey]"
          @input="event => updateSelectedData(selectedSubCategory.filterKey, event)"
          search
          stripe
          pagination
          :maxItems="maxItems"
          :data="dataList[selectedSubCategory.filterKey]"
        >
          <template slot="header">
            <h4 class="header-label">{{ selectedSubCategory.label }}</h4>
          </template>
          <template slot="thead">
            <vs-th sort-key="label">
              {{ selectedSubCategory.label }}
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr.value"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              <vs-td :data="data[indextr].text">
                {{ data[indextr].text }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- Single Type selection -->
        <vs-table
          v-show="selectedSubCategory"
          :class="{ active: subCategory.uid === selectedSubCategory.uid }"
          v-for="subCategory in singleTypeCategories"
          :key="subCategory.uid"
          :value="selectedFilters[selectedSubCategory.filterKey]"
          @input="event => updateSelectedData(selectedSubCategory.filterKey, event)"
          search
          pagination
          :maxItems="maxItems"
          :data="dataList[selectedSubCategory.filterKey]"
        >
          <template slot="header">
            <h4 class="header-label">{{ selectedSubCategory.label }}</h4>
          </template>
          <template slot="thead">
            <vs-th sort-key="label">
              {{ selectedSubCategory.label }}
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :class="{
                selected: tr.value == 0 && indextr == 0,
                'is-selected':
                  tr.value == 0 &&
                  indextr == 0 &&
                  selectedData[selectedSubCategory.filterKey] == 0,
              }"
              :data="tr.value"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              <vs-td :data="data[indextr].text">
                {{ data[indextr].text }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        
        <vs-row v-if="selectedSubCategory.uid" class="filter-footer">
          <vs-button @click="applyFilters" class="bg-primary">Apply filters</vs-button>
          <vs-button @click="clearSelectedFiltersByKey(selectedSubCategory.filterKey)" :disabled="!selectedFilters[selectedSubCategory.filterKey].length" color="danger" type="border">Clear all filters</vs-button>
        </vs-row>
      </vs-col>
      
      <vs-popup class="apply-changes-popup" title="Apply Changes Confirmation" :active.sync="applyChangesPopup">
        <p>
          Changes have been made to the {{ (selectedSubCategory.label) ? selectedSubCategory.label.toLowerCase() : '' }} that have not been applied. Do you want to apply your changes?
        </p>
        <vs-row vs-type="flex" vs-justify="right">
          <vs-button @click="confirmChanges('apply')" class="text-primary" type="border">Apply</vs-button>
          <vs-button @click="confirmChanges('discard')" color="danger" type="border">Discard</vs-button>
          <vs-button @click="confirmChanges('cancel')" color="warning" type="border">Cancel</vs-button>
        </vs-row>
      </vs-popup>
    </vs-row>
  </div>
</template>
<script>
import { pickBy, filter, sortBy, cloneDeep, isEqual, map } from "lodash";
import { filterCategories } from "./filterConstant.js";

export default {
  props: {
    popupAddFilters: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Object,
      default: () => {},
    },
    categories: {
      type: Array,
      required: true,
    },
    subCategories: {
      type: Array,
      required: true,
    },
    changeCategory: {
      type: Function,
      required: true,
    },
    changeSubCategory: {
      type: Function,
      required: true,
    },
    selectedCategory: {
      type: Object,
      default: () => {},
    },
    selectedSubCategory: {
      type: Object,
      default: () => {},
    },
    dataList: {
      type: Object,
      default: () => {},
    },
    urlType: String,
    isOvertime: {
      type: Boolean,
      default: false,
    },
    isAllClinics: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredCategories() {
      let filtered = this.categories;
      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) {
        filtered = filter(
          filtered,
          (category) => category !== filterCategories.ORGANIZATIONS
        );
      }
      
      // Remove Clinics and Nurse filter categories if isAllClinics
      if (this.isAllClinics) {
        filtered = filter(
          filtered,
          (category) => category !== filterCategories.CLINICS && category !== filterCategories.NURSE
        );
      }

      // Remove number_of_days filter if not within Nurses, Clinics, or Appointments
      if (!["SuperAdminInsightsNurses", "SuperAdminInsightsClinics", "SuperAdminInsightClinics", "SupplierInsightClinics",
            "OrgOwnerInsightClinics", "OrgOwnerInsightsNurses", "SuperAdminInsightAppointments"].includes(this.$route.name)){
        let subCatFiltered = []
        let index, counter = 0
        filtered.map(category => {
          if(category.id == filterCategories.CLINICS.id){
            index = counter;
            category.subCategory.map((subCategory) => {
              if(subCategory.uid != "clnc_number_of_days"){
                subCatFiltered.push(subCategory)
              }
            })
          }
          counter++;
        });
        try{
          filtered[index].subCategory = subCatFiltered;
        } catch (exception) {
          // Handled undefined subCategory exception
        }
      }

      return filtered;
    },
    filteredSubCategories() {
      return [this.selectedSubCategory];
    },
    multipleTypeCategories() {
      return pickBy(
        this.filteredSubCategories,
        (subCategory) => subCategory.type === "multiple"
      );
    },
    singleTypeCategories() {
      return pickBy(
        this.filteredSubCategories,
        (subCategory) => subCategory.type === "single"
      );
    },
  },
  data() {
    return {
      filterTableElm: null,
      maxItems: 20,
      selectedFilters: {},
      toBeChangedSubcategory: {},
      applyChangesPopup: false
    };
  },
  methods: {
    clearSelectedFiltersByKey(key){
      this.selectedFilters[key] = [];
    },
    confirmChanges(value) {
      if(value === 'apply') {
        this.applyChangesPopup = false
        this.applyFilters();
      } else if(value === 'discard') {
        this.clearSelectedFiltersByKey(this.selectedSubCategory.filterKey)
        this.changeSubCategoryFunc(this.toBeChangedSubcategory)
        this.applyChangesPopup = false
      } else if(value === 'cancel') {
        this.applyChangesPopup = false
      }
    },
    applyChangesConfirmation(subCategory){
      if(!isEqual(this.selectedSubCategory, subCategory)){
        const hasChanges = !isEqual(this.selectedFilters, this.selectedData)
        if (hasChanges) {
          this.applyChangesPopup = true
          this.toBeChangedSubcategory = subCategory
        } else {
          this.changeSubCategoryFunc(subCategory)
        }
      }
    },
    applyFilters(){
      this.$emit('applyFilters', this.selectedFilters)
    },
    updateSelectedData(key, value) {
      this.selectedFilters[key] = value
    },
    hasSelectedFilter(category) {
      const subCategoryFilters = category.subCategory
      const self = this
      const hasSelected = filter(subCategoryFilters, function(subCategory) { 
        return (self.selectedData[subCategory.filterKey]) ? self.selectedData[subCategory.filterKey].length : false
      })
      return (hasSelected.length)
    },
    changeSubCategoryFunc(category) {
      this.changeSubCategory(category);
      this.maxItems = 20;

      this.$nextTick(() => {
        this.sortFiltersBySelectedFirst();

        this.filterTableElm = this.$refs["filter-table"].$el.querySelector(
          ".active .con-tablex"
        );
        if (this.filterTableElm) {
          this.filterTableElm.addEventListener("scroll", this.onScroll);
        }
      });
    },
    changeCategoryFunc(category) {
      this.changeCategory(category);
    },
    sortFiltersBySelectedFirst() {
      // Once a user leaves a specific Filter and then returns they should see all selected items on top (and then followed by deselected alphabetically)
      const currentData = this.selectedFilters[this.selectedSubCategory.filterKey];
      
      const sortedDataList = sortBy(
        this.dataList[this.selectedSubCategory.filterKey],
        [
          function (o) {
            return !currentData.includes(o.value);
          },
          "text",
        ]
      );

      this.$emit("sortDataList", this.selectedSubCategory.filterKey, sortedDataList);
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight + 1 >=
        event.target.scrollHeight
      ) {
        this.maxItems += 10;
      }
    },
  },
  watch: {
    popupAddFilters(flag) {
      if (flag) {
        this.selectedFilters = cloneDeep(this.selectedData)
      }

      this.filterTableElm = this.$refs["filter-table"].$el.querySelector(
        ".active .con-tablex"
      );

      if (this.filterTableElm) {
        if (flag) {
          this.filterTableElm.scrollTop = 0;
          this.filterTableElm.addEventListener("scroll", this.onScroll);

          this.sortFiltersBySelectedFirst();
        } else {
          this.filterTableElm.removeEventListener("scroll", this.onScroll);
          this.maxItems = 20;
        }
      }
    },
  },
  created() {
    this.selectedFilters = cloneDeep(this.selectedData)
  }
};
</script>
<style lang="css">
.filter-menu-container {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 15px;
}
.filter-menu-container .filter-menu {
  display: block;
  cursor: pointer;
  padding: 5px 10px 5px 20px;
}
.filter-menu-container .filter-menu.disabled {
  color: rgba(0, 0, 0, 0.4);
}
.filter-menu-container .filter-menu:hover {
  background-color: rgba(111, 214, 198, 0.5);
}
.filter-menu-container .filter-menu.active {
  background-color: rgba(111, 214, 198, 0.2);
  border-right: 3px solid rgba(111, 214, 198, 1);
}
.filter-table-container {
  padding: 0 10px;
}
.filter-menu-container .filter-icon {
  position: relative;
  top: 2px;
  padding-left: 3px;
}

.filter-table-container .con-tablex {
  height: 450px;
}

.filter-table-container .con-td-check {
  display: none !important;
}
.filter-table-container .header-label {
  padding-left: 8px;
}
.filter-menu-container .filter-menu .con-content--item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.filter-menu-container .filter-menu .sub-category {
  padding: 5px 5px 5px 10px;
}

.filter-table-container .con-pagination-table {
  display: none;
}
.filter-footer {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}
.filter-footer button {
  margin-left: 15px;
  padding: 10px 15px !important;
}
.apply-changes-popup .vs-row {
  margin-top: 15px;
}
.apply-changes-popup .vs-row button {
  margin-left: 5px;
}
</style>
