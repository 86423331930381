<template>
  <vs-row>
    <div class="flex flex-col lg:flex-row items-start space-between space-y-4 lg:space-y-0 lg:space-x-6 mt-4 w-full 2xl:w-1/2">
        <div
          class="select-date-range w-full m-0"
          v-if="!['clinics', 'nurses'].includes(page)"
        >
          <span>Select Date Range</span>
          <custom-date-range-picker
            :dateRange="dateRange"
            @update="updateDateRangeValues"
          />
        </div>
        <vs-dropdown
          class="global-filters-dropdown w-full m-0"
          vs-custom-content
          vs-trigger-click
        >
          <vs-input
            :class="{ active: globalFiltersCount }"
            class="global-filters-input w-full"
            icon-after="true"
            icon="expand_more"
            label-placeholder="Global Filters"
            :value="placeholder"
          />
          <vs-dropdown-menu class="applied-filters-menu  w-full">
            <AppliedFilters
              :selectedData="selectedData"
              :categories="categories"
              :openFilters="openFilters"
              :clearFilterByKey="clearFilterByKey"
            />
            <vs-divider />
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <vs-button @click="popupAddFilters = true" type="flat" icon="add"
                  >Add Filters</vs-button
                >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="6"
              >
                <vs-button @click="clearAllFilters" type="flat" color="danger"
                  >Clear All Filters</vs-button
                >
              </vs-col>
            </vs-row>
          </vs-dropdown-menu>
        </vs-dropdown>
    </div>
    <vs-popup
      class="filters-popup-container"
      title="+ Add Filter"
      :active.sync="popupAddFilters"
    >
      <AddFilters
        @sortDataList="sortDataList"
        @applyFilters="applyFilters"
        :popupAddFilters="popupAddFilters"
        :selectedData="selectedData"
        :categories="categories"
        :subCategories="subCategories"
        :changeCategory="(category) => changeCategory(category)"
        :changeSubCategory="(subCategory) => changeSubCategory(subCategory)"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        :dataList="filters"
        :urlType="urlType"
        :isOvertime="isOvertime"
        :isAllClinics="isAllClinics"
      />
    </vs-popup>
  </vs-row>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  find,
  cloneDeep,
  map,
  pick,
  forEach,
  isNumber,
  isArray,
  flatten,
} from "lodash";
import CustomDateRangePicker from "./CustomDateRangePicker.vue";
import SearchAI from "../SearchAI";
import AppliedFilters from "./AppliedFilters.vue";
import AddFilters from "./AddFilters.vue";
import helper from "../../../../superAdmin/insights/helper";
import utils from "@/assets/utils";

export default {
  components: {
    CustomDateRangePicker,
    AppliedFilters,
    AddFilters,
    SearchAI
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    subCategories: {
      type: Array,
      required: true,
    },
    selectedCategory: {
      type: Object,
      required: true,
    },
    selectedSubCategory: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
    },
    page: {
      type: String,
      default: "number-of-treatment-per-treatment-area",
    },
    isOvertime: {
      type: Boolean,
      default: false,
    },
    urlType: String,
    isAllClinics: {
      type: Boolean,
      default: false,
    },
    productType: {
      type: String
    }
  },
  inject: {
    isAge: { default: false },
  },
  computed: {
    ...mapGetters("analytics", ["DEFAULT_STATE_FILTER"]),
    ...mapState("analytics", ["filters"]),
    globalFiltersCount() {
      let categoryFilterKeys = [];
      this.categories.forEach((category) => {
        category.subCategory.forEach((subCategory) => {
          categoryFilterKeys.push(subCategory.filterKey);
        });
      });
      // const categoryFilterKeys = map(this.categories, 'filterKey') // original implementation
      const activeCategories = pick(this.selectedData, categoryFilterKeys);
      let filtersCount = 0;
      forEach(activeCategories, function (value, key) {
        if (isNumber(value) && key === "quintile" && value > 0) {
          filtersCount++;
        } else if (
          typeof value === "string" &&
          key === "number_of_days" &&
          value.length
        ) {
          filtersCount++;
        } else if (
          typeof value === "string" &&
          key === "gender" &&
          value.length
        ) {
          filtersCount++;
        } else if (
          typeof value === "string" &&
          key === "treatment_category" &&
          value.length
        ) {
          filtersCount++;
        } else if (isArray(value)) {
          filtersCount += value.length;
        }
      });
      return filtersCount;
    },
    placeholder() {
      const appendMsg = (this.globalFiltersCount > 1) ? 'Filters' : 'Filter';
      if(this.globalFiltersCount) {
        return this.globalFiltersCount + ' ' + appendMsg + " Applied";
      } else {
        return 'No Filter Applied'
      }
    },
  },
  data() {
    return {
      dateRange: {
        startDate: "null",
        endDate: "null",
      },
      popupAddFilters: false,
      selectedData: {},
      postcode: {
        isLoading: false,
      },
      suburb: {
        isLoading: false,
      },
      timer: null,
      isFetching: false,
      triggerFilter: "",
      filter_query_type: [],
    };
  },
  methods: {
    ...mapActions("analytics", [
      "fetchFilterByState",
      "fetchAnalyticsFiltersSearch",
      "fetchAnalyticsFilters",
      "fetchAnalyticsFiltersSearchClinic",
      "fetchClinicState",
      "fetchAnalyticsFiltersSearchClinicNurse",
    ]),
    ...mapMutations("analytics", ["MUTATE_FILTERS", "MUTATE_FILTERS_BY"]),
    resetFilters() {
      this.selected.clnc_state = [];
      this.selected.clnc_postcode = [];
      this.selected.clnc_suburb = [];
      this.selected.ptnt_state = [];
      this.selected.ptnt_postcode = [];
      this.selected.ptnt_suburb = [];
      this.selected.brand = [];
      this.selected.product = [];
      this.selected.product_type = [];
      this.selected.dose_ml = [];
      this.selected.unit = [];
      this.selected.clinic = [];
      this.selected.org = [];
      this.selected.area = [];
      this.selected.doctor = [];
      this.selected.nurse = [];
      this.selected.gender = [];
      this.selected.patient_state = [];
      this.selected.treatment_state = [];
      this.selected.quintile = [];
      this.selected.age = [];
      this.selected.number_of_days = [];
      this.selected.selectedDate = [utils.startDate(), new Date()];
      this.selected.is_nurse = false;
      this.selected.is_age = false;
      this.selected.is_area = false;
      this.selected.is_all_clinics = false;
      this.selected.treatment_category= [];
    },
    sortDataList(key, value) {
      this.MUTATE_FILTERS_BY({ key, value });
    },
    applyFilters(selectedFilters){
      this.selectedData = cloneDeep(selectedFilters)
      this.popupAddFilters = false
    },
    updateDateRangeValues(dateRange) {
      this.selected.selectedDate = [dateRange.startDate, dateRange.endDate];
    },
    openFilters(key) {
      const subCategoryFilters = map(this.categories, "subCategory");
      const allSubCategoryFilters = flatten(subCategoryFilters);
      const subCategory = find(allSubCategoryFilters, { filterKey: key });

      this.$emit("updateSelectedSubCategory", subCategory);
      this.popupAddFilters = true;
    },
    clearFilterByKey(key) {
      this.selectedData[key] = [];
    },
    clearAllFilters() {
      this.resetFilters();
    },
    changeCategory(category) {
      this.$emit("updateSelectedCategory", category);
    },
    changeSubCategory(subCategory) {
      this.$emit("updateSelectedSubCategory", subCategory);
    },
    refreshFilters() {
      let payload = {};

      if (!["SuperAdmin", "Supplier"].includes(this.urlType))
        payload.org = [this.orgId];

      if (this.urlType === "Supplier" && this.userbrands) {
        payload.brand = this.userbrands;
        payload = {
          ...payload,
          is_supplier: true,
        };
      }

      payload = {
        ...payload,
        filter_query_type: this.filter_query_type,
      };
      

      if(this.productType) {
        payload.type = this.productType
      }

      this.$vs.loading();
      this.fetchAnalyticsFilters(payload)
        .then(() => this.$vs.loading.close())
        .catch((err) => {
          console.error("##ERROR: ", err.message);
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    selected: {
      handler(newVal) {
        this.selectedData = newVal;
      },
      immediate: true,
    },
    selectedData: {
      handler(newVal) {
        let payload = helper.generateAnalyticsAPIParameters(this.selectedData);

        if (!["SuperAdmin", "Supplier"].includes(this.urlType))
          payload.org = [this.orgId];

        if (this.urlType === "Supplier" && this.userbrands) {
          payload = {
            ...payload,
            is_supplier: true,
          };
        }

        payload = {
          ...payload,
          filter_query_type: this.filter_query_type,
        };

        if(this.productType) {
          payload.type = this.productType
        }
        
        this.$vs.loading();
        this.fetchAnalyticsFilters(payload).then(() =>
          this.$vs.loading.close()
        );
        this.$emit("updateFilters", newVal);
      },
      deep: true,
    },
    isAllClinics: {
      handler(newVal) {
        if (newVal) {
          this.selectedData.clinic = [];
          this.selectedData.clnc_state = [];
          this.selectedData.clnc_postcode = [];
          this.selectedData.clnc_suburb = [];
          this.selectedData.nurse = [];
        }
      },
    },
  },
  created() {
    if (this.selected.selectedDate.length) {
      this.dateRange = {
        startDate: this.selected.selectedDate[0],
        endDate: this.selected.selectedDate[1],
      };
    }

    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
      this.userbrands = userType.brands;
    } else {
      this.urlType = "OrgOwner";
    }
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    if (userType && userType.brands.length) {
      this.supplierCurrentBrands = userType.brands;
    }

    this.categories.map((category) => {
      this.filter_query_type.push(category.id);
    });

    this.refreshFilters();
  },
};
</script>

<style lang="css">
.removeSelectedFilters {
  position: absolute;
  margin-right: 18px;
  margin-top: 16px;
  cursor: pointer;
}
.v-calendar .input-field input {
  height: 34.4px;
}
.v-calendar .content {
  top: 35px !important;
}
.filters-popup-container .vs-popup--header {
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.filters-popup-container .vs-popup--title h3 {
  color: rgb(62, 105, 92);
}
.filters-popup-container .vs-popup--content {
  padding-left: 0;
  padding-top: 0;
  margin-top: 0;
}
.filters-popup-container .vs-popup {
  width: 700px;
}
.applied-filters-menu {
  min-width: 335px;
  max-width: 335px;
}
.global-filters-dropdown,
.global-filters-dropdown .vs-input--input {
  font-family: inherit;
}
.global-filters-dropdown .vs-input--input {
  border: 1px solid #e9e9ea !important;
}
.global-filters-dropdown .vs-con-input-label {
  margin-top: 1.4rem;
}
.global-filters-dropdown .vs-input--icon {
  font-size: 160%;
}
.global-filters-dropdown .vs-input--input+.vs-placeholder-label, .vs-input--input:focus+.vs-placeholder-label,
.select-date-range > span {
  display: block;
  font-size: .85rem;
  text-align: left;
  color: #626262;
  margin-bottom: .2rem;
}
.select-date-range .form-control {
  padding: 0.6rem;
  font-size: 1rem;
}

</style>
