<template>
  <div>
    <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        :page="`treatment-areas-per-treatment-area-${type}-${treatmentBy}`"
        @updateFilters="updateFilters"
        :urlType="urlType"
        :productType="type"
      />
      
      <!-- <div class="w-full m-5" v-if="urlType === 'Supplier'">
        <label for>All Clinics</label>
        <vs-switch
          class="m-2"
          v-model="isAllClinics"
        />
      </div> -->
      
      <div class="flex flex-col md:flex-row items-center justify-end space-y-4 md:space-y-0 w-full my-4">
        <p v-if="allTotalCount" class="optionslabel w-full md:w-1/2 text-center md:text-right">Total count of treatments: {{ Number.parseFloat(allTotalCount).toFixed(0) }}</p>
      </div>
      
      <BarChart
        :chartData="chartData"
        :chartOptions="chartOptions"
        
      />

    </vs-row>
  </div>
</template>

<script>
import BarChart from "../charts/BarChart.vue";
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"
export default {
  components: {
    BarChart,
    GlobalFilters
  },
  props: {
    type: {
      type: String,
    },
    selected: {
      type: Object,
    },
    chartDataProps: {
      type: Object,
    },
    isAllClinicsProps: {
      type: Boolean,
    },
  },  
  computed: {
    allTotalCount(){      
      let count = 0;
      const chartDataSets = this.chartData.datasets ? this.chartData.datasets : null
      if(chartDataSets) {
        chartDataSets.forEach(dataSet => {
          dataSet.data.forEach(val => {
              count += val;
          });
        });
      }

      return count
    }
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
        filterCategories.TREATMENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      isAllClinics: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "Number of Treatments",
            },
            min: 0,
            stacked: true,
          },
          x: {
            title: {
              display: true,
              text: "",
            },
            grid: {
              display: false,
            },
            stacked: true,
            ticks: {
               font: {
                size: 10
              },
              maxRotation: 90,
              minRotation: 90,
            }
          },
        },
      },
      treatmentBy: "",
      urlType: "",
    };
  },
  methods: {
    updateFilters(newVal) {
      this.$emit("updateFilters", newVal);
    },
  },
  watch: {
    chartDataProps: {
      handler(newVal){
        this.chartData = newVal;
      },
      immediate: true,
    },
    chartData: {
      handler(newVal) {
        this.$emit("updateChartData", newVal);
      },
      deep: true,
    },
    $route: {
      handler(newVal){
        if(["OrgOwnerInsightsTreatmentByNurseFiller", "OrgOwnerInsightsTreatmentByNurse"].includes(newVal.name)){
          this.treatmentBy = "nurse"
          this.chartOptions.scales.x.title.text = "Nurse"
        } else {
          this.treatmentBy = "clinic"
          this.chartOptions.scales.x.title.text = "Area"
        }
      },
      immediate: true,
    },
    isAllClinicsProps: {
      handler(newVal){
        this.isAllClinics = newVal;
      },
      immediate: true,
    },
    isAllClinics: {
      handler(newVal){
        this.$emit("updateIsAllClinics", newVal);
      }
    }
  },
  created() {
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.supplierCurrentBrands = userType.brands;
    }
  }
};
</script>
