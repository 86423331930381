<template>
    <div class="applied-filters-container">
        <div v-if="!isActiveFiltersHasData" class="no-filters">No filter applied yet</div>
        <div v-if="isActiveFiltersHasData" class="active-filters">Active filters</div>
            <div class="active-filters-row" v-for="(values, key) in activeFilters" :key="key">
                <span class="filter-icon">
                    <vs-icon icon="check_circle" color="rgb(61,201,179)"></vs-icon>
                </span>
                <span class="filter-key">{{ getCategoryLabel(key) }}: </span> 
                <span v-if="isMultipleTypeCategory(key)" class="filter-value" @click="openFilters(key)">{{ values.length }} {{ (values.length > 1) ? 'filters' : 'filter' }} applied</span>
                <span v-if="isSingleTypeCategory(key)" class="filter-value" @click="openFilters(key)">1 filter applied</span>
                <span @click="clearFilterByKey(key)" class="filter-clear">
                    <vs-icon icon="clear" color="red"></vs-icon>
                </span>
            </div>
    </div>
</template>
<script>
import { map, pick, omitBy, isEmpty, find, isNumber, isArray, flatten, forEach } from "lodash"

export default {
    props: {
        categories: {
            type: Array,
            required: true
        },
        selectedData: {
            type: Object,
            default: () => { }
        },
        openFilters: {
            type: Function,
            required: true
        },
        clearFilterByKey: {
            type: Function,
            required: true
        }
    },
    computed: {
        activeFilters() {
            const subCategoryFilterKeys = map(this.categories, function(category) {
                return map(category.subCategory, 'filterKey')
            })
            const allSubCategoryFilterKeys = flatten(subCategoryFilterKeys)
            const activeCategories = pick(this.selectedData, allSubCategoryFilterKeys)
            
            return omitBy(activeCategories, (value, key) => {
                if(isNumber(value) && key === 'quintile') {
                    return (value == 0) 
                }
                else if(isArray(value)) {
                    return isEmpty(value)
                }
            })
        },
        isActiveFiltersHasData() {
            let objValues = Object.values(this.activeFilters);

            return (objValues.length) ? true : false
        },
    },
    methods: {
        getCategoryLabel(categoryId) {
            const subCategoryFilters = map(this.categories, 'subCategory')
            const allSubCategoryFilters = flatten(subCategoryFilters)
            const subCategory = find(allSubCategoryFilters, { 'filterKey' : categoryId }) 

            return (subCategory) ? subCategory.label : '';
        },
        isMultipleTypeCategory(categoryId) {
            const subCategoryFilters = map(this.categories, 'subCategory')
            const allSubCategoryFilters = flatten(subCategoryFilters)
            const subCategory = find(allSubCategoryFilters, { 'filterKey' : categoryId }) 

            return (subCategory.type === 'multiple')
        },
        isSingleTypeCategory(categoryId) {
            const subCategoryFilters = map(this.categories, 'subCategory')
            const allSubCategoryFilters = flatten(subCategoryFilters)
            const subCategory = find(allSubCategoryFilters, { 'filterKey' : categoryId }) 

            return(subCategory.type === 'single')
        }
    }
}
</script>
<style lang="css">
.applied-filters-container .active-filters{
  background-color: rgba(111, 214, 198, .35);
  color: #626262;
  padding: 5px;
  font-size: 0.9rem;
  padding-left: 10px;
}
.applied-filters-container .no-filters{
  background-color: rgba(var(--vs-danger), .15);
  color: rgba(var(--vs-danger), 1);
  padding: 5px;
  font-size: 0.9rem;
  padding-left: 10px;
}
.applied-filters-container .filter-icon {
    position: relative;
    top: 2px;
    padding-right: 5px;
}
.applied-filters-container .active-filters-row {
    padding: 10px;
}
.applied-filters-container .filter-value {
    cursor: pointer;
    font-weight: 800;
    color: rgb(62, 105, 92);
}
.applied-filters-container .filter-value:hover {
    text-decoration: underline;
}
.applied-filters-container .filter-clear {
    cursor: pointer;
    position: relative;
    top: 2px;
    margin-left: 8px;
}
</style>

