<template>
  <vs-card class="flex-1 px-2" id="testID">

    <vs-button 
        color="dark" 
        type="flat"
        icon="arrow_back_ios"
        @click="goToInsights"
        class="p-0 lg:py-1 text-sm">
        Back to Insights
      </vs-button>
      <p class="title text-lg font-bold mt-8 lg:mt-6 mb-2"> 
        {{sidebarHeader}}
      </p>
    <template v-for="main in setLocalTreatmentOptions">
        <div 
          :key="main.urlParams"
          class="flex flex-col text-base cursor-pointer"
          @click="toggleMain(main)"
        >

          <div
            class="flex items-center justify-between py-2"
            :class="(main.isActive && !main.subMenu.length && main.urlParams !== 'all') ? 'categoryActive px-4 font-medium' : 'hover:font-medium'"
          >
            <span
              >{{main.text}}
            </span>
            <template v-if="main.subMenu.length">
              <vs-icon v-if="main.isActive" icon="expand_more"></vs-icon>
              <vs-icon v-else icon="chevron_right"></vs-icon>
            </template>
          </div>

          <div
            v-if="main.subMenu.length && main.isActive"
            class="font-medium flex flex-col space-y-1"
          >
            <div
              v-for="(item, index) in main.subMenu"
              :data="item"
              :key="index"
              @click.prevent.stop="showTreatment(item, main)"
            >
              <p 
                class="listHover px-4 py-2 cursor-pointer font-medium"
                :class="item.isActive ? 'categoryActive' : 'bg-gray-100 rounded-lg'"
              >{{ item.text }}</p>
            </div>
          </div>
          
      </div>
    </template>

  </vs-card>
</template>


<script>

export default {
  props: {
    sidebarHeader: {
      type: String, 
      default: "Treatment Areas"
    },
    treatmentCategories: {
      type: Array
    },
    setTreatmentOptions: {
      type: Array
    }
  },
  data() {
    return {
      setLocalTreatmentOptions: [],
      urlType: "",
    };
  },
  methods: {
    goToInsights() {
      this.$router.push({ name: `${this.urlType}Insights` });
    },
    goToPage(value){
      if(value){
        this.$router.push({name: value})
      }
    },
    toggleMain(main){
      if(main.subMenu.length){
        main.isActive = !main.isActive;
        return;
      }
      this.setLocalTreatmentOptions.forEach((item) => {
        item.isActive = false;
        item.subMenu.forEach((menu) => {
          menu.isActive = false;
        })
      })
      main.isActive = !main.isActive;
      if(main.url && this.$route.name !== main.url){
        this.$router.push({name: main.url})
      }
    },
    showTreatment(item, mainItem) {
      if(this.$route.name !== item.url){
        this.$router.push({name: item.url})
      }
      this.setLocalTreatmentOptions.forEach((e) => {
        e.isActive = false;
        e.subMenu.forEach((menu) => {
          menu.isActive = false;
        })
      })
      item.isActive = true;
      mainItem.isActive = true;
    },
  },
  watch:{
    setTreatmentOptions: {
      handler(newVal){
        this.setLocalTreatmentOptions = newVal;
      },
      immediate: true,
    },
    setLocalTreatmentOptions: {
      handler(newVal) {
        this.$emit("updateTreatmentOptions", newVal);
      },
      deep: true,
    }
  },
  created() {
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
  }
};
</script>

<style>
  .listHover:hover:not(.categoryActive) {
    background-color: rgba(111, 214, 198, 0.6);
    color: #3E695C;
  }
  .categoryActive {
    background-color: #074230;
    color: white;
    border-radius: 0.5em;
  }
  .categoryActive span {
    color: white !important;
  }
</style>