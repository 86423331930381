<template>
  <div class="flex flex-col gap-2">
    <vs-row>
      <TabInsights
        :title="`Number of Treatments per Treatment Area (${treatmentType.toUpperCase()})`"
        :hasQuickInsights="false"
      >
        <TreatmentAreas
          :type="treatmentType"
          :selected="selected"
          :chartDataProps="chartData"
          @updateChartData="chartData = $event"
          :isAllClinicsProps="isAllClinics"
          @updateIsAllClinics="isAllClinics = $event"
          @updateFilters="selected = $event"
        />
      </TabInsights>
    </vs-row>

    <!-- <div class="flex justify-end mt-2 mb-2 w-full">
      <vs-button class="w-full lg:w-auto" @click="handleExportToCSV">
        Export
      </vs-button>
    </div> -->
    
    <vs-table
      v-if="chartData && chartData.labels"
      max-items="10"
      pagination
      noDataText="No Data Available"
      :data="chartData.labels"
      :key="'main-'+tableKey"
    >
      <template slot="thead">
        <vs-th
          style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 50vw;
            max-width: 50vw;
          "
          >{{  isArea?'AREA': isAge?'AGE': isNurse?'NURSE': 'CLINIC' }}</vs-th
        >
        <vs-td   style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 50vw;
            font-weight: bold;
            font-size: .85rem;
            max-width: 50vw;
          " :key="index" v-for="(product, index) in chartData.datasets">
          <template v-if="product.label!==''">{{ product.label.toUpperCase()}}</template>
        </vs-td>
        <vs-th style="background-color: rgba(235, 247, 244, 1); color: #5e5873"
          >TOTAL TREATMENTS</vs-th
        >
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="`per-area-${indextr}`" v-for="(tr, indextr) in data">
          <vs-td :data="tr">
            {{ tr }}
          </vs-td>
          <vs-td :key="index" v-for="(product, index) in chartData.datasets">
            <template v-if="product.label!==''">{{ product.data[indextr] }}</template>
          </vs-td>
          <vs-td :data="tr">
            {{ getTotalTreatments(tr) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import TreatmentAreas from "../../components/insights/treatments/TreatmentAreas.vue";
import TabInsights from "../../components/insights/TabsInsights.vue";
import Sidebar from "./Sidebar.vue";
import utils from '@/assets/utils'
import moment from 'moment';
import helper from "./helper";
import { map, join } from "lodash"

export default {
  components: {
    TreatmentAreas,
    TabInsights,
    Sidebar,
  },
  props:{
    isArea: Boolean,
    isAge: Boolean,
    isNurse: Boolean,
    isAllClinicsProps: Boolean,
  },
  data() {
    return {
      selected: {},
      chartData: {},
      treatmentType: "",
      urlType: "",
      orgId: "",
      isAllClinics: false,
      supplierCurrentBrands: [],
      tableKey: "",
    };
  },
  provide(){
    return {
      isAge: this.isAge ? this.isAge : false
    }
  },
  computed: {
    ...mapState('analytics', ['FILTERS_SELECTED']),
  },
  methods: {
    ...mapActions("analytics", ["fetchTreatmentAreas"]),
    getTreatmentAreas() {
      this.$vs.loading();

      // this.selected.is_area = this.isArea;
      // this.selected.is_age = this.isAge;
      // this.selected.is_nurse = this.isNurse;
      // this.selected.product_type = this.treatmentType;

      let payload = helper.generateAnalyticsAPIParameters(this.selected)
      payload.is_area = this.isArea;
      payload.is_age = this.isAge;
      payload.is_nurse = this.isNurse;
      payload.type = this.treatmentType;

      if(this.urlType === "Supplier" && !this.isAllClinics){
        if(this.selected.brand.length){
          payload.brand = this.selected.brand
        }
        else {
          payload.brand = this.supplierCurrentBrands
        }
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload.org = [this.orgId]
      }
      this.fetchTreatmentAreas(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("# ERR: ", err.message);
          this.$vs.loading.close();
        });
    },
    getTotalTreatments(index) {
      let dataIndex = this.chartData.labels.findIndex((e) => e === index);
      let totalAmount = this.chartData.datasets.reduce((total, curr) => {
        total += curr.data[dataIndex];
        return total;
      }, 0);
      return totalAmount;
    },
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = `number_of_treatments_per_treatment_area(${this.treatmentType})_`

      const datasetsLabel = map(this.chartData.datasets, function(data) {
        return `${data.label.toUpperCase().replace(/,/g,' ')}`;
      });

      const headerLabels = join(datasetsLabel, ',');
      const csvHeaderFirstColumn = this.isArea ? "AREA" : this.isAge ? "AGE" : this.isNurse ? "NURSE" : "CLINIC"
      csv += csvHeaderFirstColumn + ",";
      csv += (this.isArea) ? "TOTAL TREATMENTS\n" : headerLabels + ",TOTAL TREATMENTS\n";
      
      this.chartData.labels.forEach((row, index) => {
        if(this.isArea) {
          csv += `${row.replace(/,/g,' ')},`;
        } else {
          csv += `${row},`;
          this.chartData.datasets.forEach(dataSet => {
            csv += `${dataSet.data[index]},`
          })
        }
        csv += `${this.getTotalTreatments(row)}\n`;
      });

      utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
    }
  },
  watch: {
    $route:  {
      handler(newVal){
        if([
          "SuperAdminAverageTreatmentsPerTreatmentAreaFiller", 
          "OrgOwnerAverageTreatmentsPerTreatmentAreaFiller",
          "SupplierAverageTreatmentsPerTreatmentAreaFiller"
        ].includes(newVal.name)){
          this.treatmentType = "filler";
        } else if([
          "SuperAdminAverageTreatmentsPerTreatmentAreaToxin", 
          "OrgOwnerAverageTreatmentsPerTreatmentAreaToxin",
          "SupplierAverageTreatmentsPerTreatmentAreaToxin"
        ].includes(newVal.name)){
          this.treatmentType = "toxin";
        } else {
          this.treatmentType = "all";
        }
        // setTimeout(() => {
        //   this.getTreatmentAreas();
        // }, 250);
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.getTreatmentAreas();
      },
      deep: true,
    },
    // isAllClinicsProps: {
    //   handler(val) {
    //     this.isAllClinics = val;
    //   },
    //   immediate: true,
    // },
    // isAllClinics: {
    //   handler(val){
    //     this.$emit("updateIsAllClinics", val);
    //     this.getTreatmentAreas();
    //   }
    // },
    chartData: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2)
        this.tableKey = randNumber
      },
      deep: true,
    },
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED)
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.supplierCurrentBrands = userType.brands;
    }
  }
};
</script>
