import utils from "@/assets/utils";

export default {
    generateAnalyticsAPIParameters(selectedData) {
        let payload = {
            clnc_state: selectedData.clnc_state,
            clnc_postcode: selectedData.clnc_postcode,
            clnc_suburb: selectedData.clnc_suburb,
            ptnt_state: selectedData.ptnt_state,
            ptnt_postcode: selectedData.ptnt_postcode,
            ptnt_suburb: selectedData.ptnt_suburb,
            brand: selectedData.brand,
            product: selectedData.product,
            product_type: selectedData.product_type,
            dose_ml: selectedData.dose_ml,
            unit: selectedData.unit,
            clinic: selectedData.clinic,
            org: selectedData.org,
            area: selectedData.area,
            doctor: selectedData.doctor,
            nurse: selectedData.nurse,
            gender: selectedData.gender,
            patient_state: selectedData.patient_state,
            treatment_state: selectedData.treatment_state,
            quintile: selectedData.quintile,
            age: selectedData.age,
            number_of_days: selectedData.number_of_days,
            start: utils.formattedDate(selectedData.selectedDate[0]),
            end: utils.formattedDate(selectedData.selectedDate[1]),
            is_nurse: selectedData.is_nurse,
            is_age: selectedData.is_age,
            is_area: selectedData.is_area,
            type: selectedData.type,
            is_all_clinics: selectedData.is_all_clinics,
            is_standard: selectedData.is_standard,
            treatment_category: selectedData.treatment_category,
        };
        return payload
    }
}