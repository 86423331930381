const filterCategory = {
    ORGS: { id: 'orgs', label: 'Organizations', filterKey: 'org', type: 'multiple' },
    STATES: { id: 'states', label: 'States', filterKey: 'state', type: 'multiple' },
    POSTCODES: { id: 'postcodes', label: 'Postcodes', filterKey: 'postcode', type: 'multiple' },
    SUBURBS: { id: 'suburbs', label: 'Suburbs', filterKey: 'suburb', type: 'multiple' },
    AREAS: { id: 'area', label: 'Area', filterKey: 'area', type: 'multiple' },
    CLINICS: { id: 'clinics', label: 'Clinics', filterKey: 'clinic', type: 'multiple' },
    BRANDS: { id: 'brands', label: 'Brands', filterKey: 'brand', type: 'multiple' },
    NURSES: { id: 'nurses', label: 'Nurses', filterKey: 'nurse_id', type: 'multiple' },
    AGE: { id: 'age', label: 'Age', filterKey: 'age', type: 'multiple' },
    PRODUCTS: { id: 'products', label: 'Products', filterKey: 'product', type: 'multiple' },
    QUINTILE: { id: 'quintile', label: 'Quintile', filterKey: 'quintile', type: 'single' },
    NUMBER_OF_DAYS: { id: 'number_of_days', label: 'Number of Days', filterKey: 'number_of_days', type: 'single' },

}

const organizationCategory = [
    { uid: 'orgnztn_orgs', id: 'orgs', label: 'Organizations', filterKey: 'org', type: 'multiple' },
]

const clinicCategory = [
    { uid: 'clnc_states', id: 'states', label: 'States', filterKey: 'clnc_state', type: 'multiple' },
    { uid: 'clnc_postcodes', id: 'postcodes', label: 'Postcodes', filterKey: 'clnc_postcode', type: 'multiple' },
    { uid: 'clnc_suburbs', id: 'suburbs', label: 'Suburbs', filterKey: 'clnc_suburb', type: 'multiple' },
    { uid: 'clnc_clinics', id: 'clinics', label: 'Clinics', filterKey: 'clinic', type: 'multiple' },
    // { uid: 'clnc_quintile', id: 'quintile', label: 'Quintile', filterKey: 'quintile', type: 'single' },
    { uid: 'clnc_number_of_days', id: 'number_of_days', label: 'Number of Days', filterKey: 'number_of_days', type: 'single' },
]

const patientCategory = [
    // { uid: 'ptnt_states', id: 'states', label: 'States', filterKey: 'ptnt_state', type: 'multiple' },
    // { uid: 'ptnt_postcodes', id: 'postcodes', label: 'Postcodes', filterKey: 'ptnt_postcode', type: 'multiple' },
    // { uid: 'ptnt_suburbs', id: 'suburbs', label: 'Suburbs', filterKey: 'ptnt_suburb', type: 'multiple' },
    { uid: 'ptnt_age', id: 'ages', label: 'Age', filterKey: 'age', type: 'multiple' },
    { uid: 'ptnt_gender', id: 'gender', label: 'Gender', filterKey: 'gender', type: 'single' },
]

const appointmentCategory = [
    // { uid: 'appntmnt_patient_state', id: 'patient_state', label: 'New / Returning Patient', filterKey: 'patient_state', type: 'single' },
    // { uid: 'appntmnt_treatment_state', id: 'treatment_state', label: 'Single / Multiple Treatments', filterKey: 'treatment_state', type: 'single' },
    // { uid: 'appntmnt_doctors', id: 'doctors', label: 'Doctors', filterKey: 'doctor', type: 'multiple' },
]

const treatmentCategory = [
    { uid: 'trtmnt_brands', id: 'brands', label: 'Brands', filterKey: 'brand', type: 'multiple' },
    { uid: 'trtmnt_dose_ml', id: 'dose_ml', label: 'Dose (mL)', filterKey: 'dose_ml', type: 'multiple' },
    { uid: 'trtmnt_units', id: 'units', label: 'Units', filterKey: 'unit', type: 'multiple' },
    { uid: 'trtmnt_products', id: 'products', label: 'Products', filterKey: 'product', type: 'multiple' },
    { uid: 'trtmnt_product_type', id: 'product_type', label: 'Product Type', filterKey: 'product_type', type: 'multiple' },
    { uid: 'trtmnt_area', id: 'area', label: 'Area', filterKey: 'area', type: 'multiple' },
]

const nurseCategory = [
    { uid: 'nrs_nurses', id: 'nurses', label: 'Nurses', filterKey: 'nurse', type: 'multiple' },
    { uid: 'nrs_total_treatments', id: 'nrs_total_treatments', label: 'Nurse Experience', filterKey: 'treatment_category', type: 'single' },
]

const filterCategories = {
    ORGANIZATIONS: { id: 'organizations', label: 'Organizations', filterKey: 'organizations', subCategory: organizationCategory },
    CLINICS: { id: 'clinics', label: 'Clinics', filterKey: 'clinics', subCategory: clinicCategory },
    NURSE: { id: 'nurse', label: 'Nurse', filterKey: 'nurse', subCategory: nurseCategory },
    PATIENTS: { id: 'patients', label: 'Patients', filterKey: 'patients', subCategory: patientCategory },
    // APPOINTMENTS: { id: 'appointments', label: 'Appointments', filterKey: 'appointments', subCategory: appointmentCategory },
    TREATMENTS: { id: 'treatments', label: 'Treatments', filterKey: 'treatments', subCategory: treatmentCategory },
}

// export default filterCategories;

export {
    filterCategories,
    filterCategory,
};