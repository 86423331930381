<template>
  <div class="customcard">
    <vs-tabs class="mb-8"> 
      <slot /> 
    </vs-tabs>
    <QuickInsights :class="{ hidden: !hasQuickInsights }" />
  </div>
</template>

<script>
import QuickInsights from "./QuickInsights.vue";
export default {
  components: {
    QuickInsights,
  },
  props: {
    title: String,
    toolTiptext: String,
    hasQuickInsights: {
      type: Boolean,
      default: false,
    },
    setTreatmentOptions: {
      type: Array,
    }
  },
  data() {
    return {
      setLocalTreatmentOptions: {},
    };
  },
  watch: {
    setTreatmentOptions: {
      handler(newVal) {
        this.setLocalTreatmentOptions = newVal;
      },
      immediate: true,
    },
    setLocalTreatmentOptions: {
      handler(newVal) {
        this.$emit("updateTreatmentOptions", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .customcard {
    width: 100%;
    min-height: 55vh;
  }
</style>
